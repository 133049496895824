<template>
  <section>
    <div class="content-header">
      <h2>Works cited </h2>
    </div>
    <div class="content-wrapper">
      <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared.</p>
      <ul>
        <li class="pb-3">Brainstorm Strategy Group Inc. (2021, July 13). Canadian Campus Career Directory.<br><a href="https://www.brainstorm.ca/canadian-campus-career-directory" target="_blank"> www.brainstorm.ca/canadian-campus-career-directory </a></li>
        <li class="pb-3">Centennial College. (2021). Career Resilience Toolkit.<br><a href="https://www.centennialcollege.ca/student-life/career-services-and-co-operative-education/career-resilience/about-the-career-resilience-toolkit/" target="_blank"> www.centennialcollege.ca/student-life/career-services-and-co-operative-education/career-resilience/about-the-career-resilience-toolkit/ </a></li>
        <li class="pb-3">Basso, J. (2019, March 14). <em>Lessons from Wilfrid Laurier University’s Career-Integrated Learning program</em>. CareerWise. <a href="https://careerwise.ceric.ca/2019/03/04/lessons-from-wilfrid-laurier-universitys-career-integrated-learning-program/#.YXL1qtnMLUJ " target="_blank">careerwise.ceric.ca/2019/03/04/lessons-from-wilfrid-laurier-universitys-career-integrated-learning-program/#.YXL1qtnMLUJ </a></li>
        <li class="pb-3"><em>The Career Resources Questionnaire</em>. (n.d.). Cresogo. <br><a href="http://www.cresogo.com/crqmainpage " target="_blank">www.cresogo.com/crqmainpage </a></li>
        <li class="pb-3">Apple Podcasts. (2021, April 5). Navigating Career Turbulence.<br> <a href="https://podcasts.apple.com/us/podcast/navigating-career-turbulence/id1346314086?i=1000515923769" target="_blank">podcasts.apple.com/us/podcast/navigating-career-turbulence/id1346314086?i=1000515923769</a></li>
        <li class="pb-3">Burnett, B., & Evans, D. (2016). <em>Designing Your Life: How to Build a Well-Lived, Joyful Life</em> (Illustrated ed.). Knopf.<a href="" target="_blank"></a></li>
        <li class="pb-3">Burnett, B., & Evans, D. (2020). <em>Designing Your Work Life: How to Thrive and Change and Find Happiness at Work</em> (Illustrated ed.). Knopf.</li>
        <li class="pb-3">Burnett, B., & Evans, D. (2021). <em>Designing Your New Work Life</em>. Vintage.</li>
        <li class="pb-3">Peterson, S., & Dalipe, C. (2021). <em>Designing Your Life</em>. Designing Your Life. <br><a href="https://designingyour.life/ " target="_blank">designingyour.life/ </a></li>
        <li class="pb-3">Apple Podcasts. (2020, January 30). <em>Designing Your Work Life: Bill Burnett</em>.<br> <a href="https://podcasts.apple.com/us/podcast/designing-your-work-life-bill-burnett/id1231685425?i=1000464165949" target="_blank">podcasts.apple.com/us/podcast/designing-your-work-life-bill-burnett/id1231685425?i=1000464165949</a></li>
        <li class="pb-3"><em>5 steps to designing the life you want | Bill Burnett | TEDxStanford</em>. (2017, May 19). [Video]. YouTube. <a href="https://www.youtube.com/watch?v=SemHh0n19LA " target="_blank">www.youtube.com/watch?v=SemHh0n19LA </a></li>
        <li class="pb-3">Centennial College. (n.d.). <em>Tips and Resources from “Job Searching in Times of Uncertainty”</em> </li>
        <li class="pb-3">Work-Life Balance Quiz. (2021, August 6). CMHA National.<br> <a href="https://cmha.ca/find-info/mental-health/check-in-on-your-mental-health/work-life-balance-quiz/#balance-quiz " target="_blank">cmha.ca/find-info/mental-health/check-in-on-your-mental-health/work-life-balance-quiz/#balance-quiz </a></li>
        <li class="pb-3">What’s Your Stress Index? (2021, August 10). CMHA National. <br><a href="https://cmha.ca/find-info/mental-health/check-in-on-your-mental-health/whats-your-stress-index/ " target="_blank">cmha.ca/find-info/mental-health/check-in-on-your-mental-health/whats-your-stress-index/ </a></li>
        <li class="pb-3">Centennial College. (n.d.). <em>Career Readiness for Students with Disabilities</em> </li>
        <li class="pb-4">Jiang, J. (2016, December 7). <em>What I learned from 100 days of rejection</em> [Video]. TED Talks. <a  href="https://www.ted.com/talks/jia_jiang_what_i_learned_from_100_days_of_rejection " target="_blank">www.ted.com/talks/jia_jiang_what_i_learned_from_100_days_of_rejection </a>
        <!-- <div class="video-player pt-4"> -->
              <!-- video element -->
              <!-- <vue-plyr>
                <video
                  controls
                  crossorigin
                  playsinline
                >
                  <source
                    size="720"
                    src="https://staging.gevc.ca/centennial/tic/videos/career/share/12b.mp4"
                    type="video/mp4"
                  /> -->
                  <!-- <track
                    label="English"
                    kind="subtitles"
                    srclang="en"
                    src="https://staging.gevc.ca/centennial/tic/cc/career/share/12.en.vtt"
                    default
                  > -->
                <!-- </video>
              </vue-plyr>
              <div class="accordion">
                <accordion
                  headerText="Transcript"
                  accordionID="transcript01"
                >
                  <p>Descriptive video text appears here </p>
                  <p>Thanks.</p>
                </accordion>
          </div></div>--></li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '12',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
